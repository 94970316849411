import config from 'config'
import 'whatwg-fetch'

import store from 'store/store'
import session from 'modules/stores/session'
import { newBugsnagClient } from 'modules/helpers/BugsnagClient' 

// ------------------------------------
// Constants
// ------------------------------------
export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const PATCH = 'PATCH'
export const DELETE = 'DELETE'

export function request (endpoint, type, data, successCallback, failureCallback, additionalHeaders = {}) {
  rawRequest(config.apiHost, endpoint, type, data, successCallback, failureCallback, additionalHeaders)
}

export function rawRequest (host, endpoint, type, data, successCallback, failureCallback, additionalHeaders = {}) {
  const fullEndpoint = host + '/' + endpoint
  const jsonBody = (data) ? JSON.stringify(data) : undefined

  const state = store.getState()

  function parseJSON (response) {
    const latestMarketingVersion = response.headers.get('Latest-Marketing-App-Version')
    const currentVersion = session.selectors.getCurrentAppVersion(state)

    if (latestMarketingVersion !== null &&
        (latestMarketingVersion !== currentVersion)) {
      store.dispatch(session.actions.setAppVersion(latestMarketingVersion))
    }
    if (response.ok) {
      return response.json()
        .then(function (payload) {
          successCallback(payload)
        })
    } else {
      return response.json()
        .then(function (errors) {
          failureCallback(errors)
        })
    }
  }

  function handleException (exception) {
    if (exception) {
      const error = { server: exception.message }
      failureCallback({ errors: error })
      const bugsnagClient = newBugsnagClient()
      bugsnagClient.notify(exception)
    }
  }

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Client-ID': config.appName,
    ...additionalHeaders
  }

  let requestData = {
    headers: headers,
    method: type,
    credentials: 'include'
  }
  if (jsonBody) {
    requestData = { ...requestData, body: jsonBody }
  }

  fetch(fullEndpoint, requestData)
    .then(parseJSON)
    .then(handleException)
    .catch(function(exception) {
      const bugsnagClient = newBugsnagClient()
      bugsnagClient.notify(exception)
    })
}

export function buildIndexUrl(base, page, sort, search) {
    let url = base + '?page[number]=' + page
    if (sort) { url = url + '&sort=' + sort }
    if (search) { url = url + '&search=' + search }
    return url
}

export function paramStringWithName(name, values) {
  if (!name || !values || !Array.isArray(values) || values.length < 1)
    return null;
  let string = "";
  values.forEach(value => {
    string = string + name + "[]=" + value + "&";
  });

  // remove trailing '&'
  return string.slice(0, -1);
}