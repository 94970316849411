const appName = "Marketing";
const version = "1.0.0";
const environment = process.env.REACT_APP_ENVIRONMENT;

const defaultConfig = {
  version: version,
  apiPort: 3000,
  appName: appName,
  apiHost: process.env.REACT_APP_API_HOST,
  urlHost: process.env.REACT_APP_URL_HOST,
  mainHost: process.env.REACT_APP_MAIN_HOST,
  bugsnagApiKey: "8f69a499fd677286e5bf489847d169db",
  app: {
    title: "GymLeads Marketing",
    description: "GymLeads Marketing",
  },
};

let config = {};

if (environment === "development" || environment === "test") {
  config = Object.assign({
    ...defaultConfig,
    intercom_app_id: "",
    debug: true,
  });
}

if (environment === "staging") {
  config = Object.assign({
    ...defaultConfig,
    intercom_app_id: "",
    debug: true,
  });
}

if (environment === "production") {
  config = Object.assign({
    ...defaultConfig,
    intercom_app_id: "f8f1vqq3",
    debug: false,
  });
}

export default config;
