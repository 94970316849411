import { request, GET } from 'utils/apiUtils'
import session from 'modules/stores/session'

export const loadSsoSession = (token) => {
  return (dispatch) => {

    function onFailure (payload) {

    }

    function onSuccess (payload) {
      if (payload) {
        dispatch(session.actions.startSession(payload, true))
      }
    }

    const url = 'sso';
    const headers = {
      "X-AUTH-TOKEN": `${token}`,
    };

    return request(url, GET, null, onSuccess, onFailure, headers)
  }
}
